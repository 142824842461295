export const countries = [
  {
    value: 'tr',
    currency: 'try',
    localizationKey: 'common.countries.turkey',
    region: 'common.regions.asia',
    areaCode: '90',
    phoneLength: 10,
    phoneFormat: '(###) ### ## ##',
    postCodeFormat: '#####',
    inputPostCodeFormat: '#####'
  },
  {
    value: 'al',
    currency: 'eur',
    localizationKey: 'common.countries.albania',
    region: 'common.regions.europe',
    areaCode: '355',
    postCodeFormat: '####',
    inputPostCodeFormat: '####'
  },
  {
    value: 'dz',
    currency: 'usd',
    localizationKey: 'common.countries.algeria',
    region: 'common.regions.africa',
    areaCode: '213',
    postCodeFormat: '#####',
    inputPostCodeFormat: '#####'
  },
  {
    value: 'au',
    currency: 'usd',
    localizationKey: 'common.countries.australia',
    region: 'common.regions.pacifica',
    areaCode: '61',
    postCodeFormat: '####',
    inputPostCodeFormat: '####'
  },
  {
    value: 'at',
    currency: 'eur',
    localizationKey: 'common.countries.austria',
    region: 'common.regions.europe',
    areaCode: '43',
    postCodeFormat: '####',
    inputPostCodeFormat: '####'
  },
  {
    value: 'az',
    currency: 'usd',
    localizationKey: 'common.countries.azerbaijan',
    region: 'common.regions.asia',
    areaCode: '994',
    postCodeFormat: 'AZ####',
    postCodePrefix: 'AZ',
    inputPostCodeFormat: 'AA####'
  },
  {
    value: 'be',
    currency: 'eur',
    localizationKey: 'common.countries.belgium',
    region: 'common.regions.europe',
    areaCode: '32',
    postCodeFormat: '####',
    inputPostCodeFormat: '####'
  },
  {
    value: 'ba',
    currency: 'eur',
    localizationKey: 'common.countries.bosnia_herzegovina',
    region: 'common.regions.europe',
    areaCode: '387',
    postCodeFormat: '#####',
    inputPostCodeFormat: '#####'
  },
  {
    value: 'br',
    currency: 'usd',
    localizationKey: 'common.countries.brazil',
    region: 'common.regions.america',
    areaCode: '55',
    postCodeFormat: '#####',
    inputPostCodeFormat: '#####'
  },
  {
    value: 'bg',
    currency: 'eur',
    localizationKey: 'common.countries.bulgaria',
    region: 'common.regions.europe',
    areaCode: '359',
    postCodeFormat: '####',
    inputPostCodeFormat: '####'
  },
  {
    value: 'ca',
    currency: 'usd',
    localizationKey: 'common.countries.canada',
    region: 'common.regions.america',
    areaCode: '1',
    postCodeFormat: 'A#A #A#',
    inputPostCodeFormat: 'A#A #A#'
  },
  {
    value: 'cn',
    currency: 'usd',
    localizationKey: 'common.countries.china',
    region: 'common.regions.asia',
    areaCode: '86',
    postCodeFormat: '######',
    inputPostCodeFormat: '######'
  },
  {
    value: 'kk',
    currency: 'eur',
    localizationKey: 'common.countries.northern_cyprus',
    region: 'common.regions.asia',
    areaCode: '90',
    phoneFormat: '(###) ### ## ##',
    postCodeFormat: '#####',
    inputPostCodeFormat: '#####'
  },
  {
    value: 'cz',
    currency: 'eur',
    localizationKey: 'common.countries.czechia',
    region: 'common.regions.europe',
    areaCode: '420',
    postCodeFormat: '### ##',
    inputPostCodeFormat: '### ##'
  },
  {
    value: 'dk',
    currency: 'eur',
    localizationKey: 'common.countries.denmark',
    region: 'common.regions.europe',
    areaCode: '45',
    postCodeFormat: '####',
    inputPostCodeFormat: '####'
  },
  {
    value: 'ee',
    currency: 'eur',
    localizationKey: 'common.countries.estonia',
    region: 'common.regions.europe',
    areaCode: '372',
    postCodeFormat: '#####',
    inputPostCodeFormat: '#####'
  },
  {
    value: 'fi',
    currency: 'eur',
    localizationKey: 'common.countries.finland',
    region: 'common.regions.europe',
    areaCode: '358',
    postCodeFormat: '#####',
    inputPostCodeFormat: '#####'
  },
  {
    value: 'fr',
    currency: 'eur',
    localizationKey: 'common.countries.france',
    region: 'common.regions.europe',
    areaCode: '33',
    postCodeFormat: '#####',
    inputPostCodeFormat: '#####'
  },
  {
    value: 'de',
    currency: 'eur',
    localizationKey: 'common.countries.germany',
    region: 'common.regions.europe',
    areaCode: '49',
    postCodeFormat: '#####',
    inputPostCodeFormat: '#####'
  },
  {
    value: 'gr',
    currency: 'eur',
    localizationKey: 'common.countries.greece',
    region: 'common.regions.europe',
    areaCode: '30',
    postCodeFormat: '#####',
    inputPostCodeFormat: '#####'
  },
  {
    value: 'hk',
    currency: 'usd',
    localizationKey: 'common.countries.hong_kong',
    region: 'common.regions.asia',
    areaCode: '852',
    postCodeFormat: 'none',
    inputPostCodeFormat: 'none'
  },
  {
    value: 'hu',
    currency: 'eur',
    localizationKey: 'common.countries.hungary',
    region: 'common.regions.europe',
    areaCode: '36',
    postCodeFormat: '####',
    inputPostCodeFormat: '####'
  },
  {
    value: 'id',
    currency: 'usd',
    localizationKey: 'common.countries.indonesia',
    region: 'common.regions.pacifica',
    areaCode: '62',
    postCodeFormat: '#####',
    inputPostCodeFormat: '#####'
  },
  {
    value: 'kr',
    currency: 'eur',
    localizationKey: 'common.countries.korea_republic_of',
    region: 'common.regions.pacifica',
    areaCode: '82',
    postCodeFormat: '#####',
    inputPostCodeFormat: '#####'
  },
  {
    value: 'ie',
    currency: 'eur',
    localizationKey: 'common.countries.ireland',
    region: 'common.regions.europe',
    areaCode: '353',
    postCodeFormat: 'A##A###',
    inputPostCodeFormat: 'A##A###'
  },
  {
    value: 'it',
    currency: 'eur',
    localizationKey: 'common.countries.italy',
    region: 'common.regions.europe',
    areaCode: '39',
    postCodeFormat: '#####',
    inputPostCodeFormat: '#####'
  },
  {
    value: 'jp',
    currency: 'usd',
    localizationKey: 'common.countries.japan',
    region: 'common.regions.pacifica',
    areaCode: '81',
    postCodeFormat: '###-####',
    inputPostCodeFormat: '###-####'
  },
  {
    value: 'kg',
    currency: 'usd',
    localizationKey: 'common.countries.kyrgyzstan',
    region: 'common.regions.asia',
    areaCode: '996',
    postCodeFormat: '#####',
    inputPostCodeFormat: '#####'
  },
  {
    value: 'kz',
    currency: 'usd',
    localizationKey: 'common.countries.kazakhstan',
    region: 'common.regions.asia',
    areaCode: '7',
    postCodeFormat: '#####',
    inputPostCodeFormat: '#####'
  },
  {
    value: 'lt',
    currency: 'eur',
    localizationKey: 'common.countries.lithuania',
    region: 'common.regions.europe',
    areaCode: '370',
    postCodeFormat: 'LT-#####',
    postCodePrefix: 'LT-',
    inputPostCodeFormat: 'AA-#####'
  },
  {
    value: 'lu',
    currency: 'eur',
    localizationKey: 'common.countries.luxembourg',
    region: 'common.regions.europe',
    areaCode: '352',
    postCodeFormat: 'L-####',
    postCodePrefix: 'L-',
    inputPostCodeFormat: 'A-####'
  },
  {
    value: 'mt',
    currency: 'eur',
    localizationKey: 'common.countries.malta',
    region: 'common.regions.europe',
    areaCode: '356',
    postCodeFormat: '### ##',
    inputPostCodeFormat: '### ##'
  },
  {
    value: 'mx',
    currency: 'usd',
    localizationKey: 'common.countries.mexico',
    region: 'common.regions.america',
    areaCode: '52',
    postCodeFormat: '#####',
    inputPostCodeFormat: '#####'
  },
  {
    value: 'md',
    currency: 'eur',
    localizationKey: 'common.countries.moldova',
    region: 'common.regions.europe',
    areaCode: '373',
    postCodeFormat: 'MD-####',
    postCodePrefix: 'MD-',
    inputPostCodeFormat: 'AA-####'
  },
  {
    value: 'nl',
    currency: 'eur',
    localizationKey: 'common.countries.netherlands',
    region: 'common.regions.europe',
    areaCode: '31',
    postCodeFormat: '#### AA',
    inputPostCodeFormat: '#### AA'
  },
  {
    value: 'nz',
    currency: 'usd',
    localizationKey: 'common.countries.new_zealand',
    region: 'common.regions.pacifica',
    areaCode: '64',
    postCodeFormat: '####',
    inputPostCodeFormat: '####'
  },
  {
    value: 'no',
    currency: 'eur',
    localizationKey: 'common.countries.norway',
    region: 'common.regions.europe',
    areaCode: '47',
    postCodeFormat: '####',
    inputPostCodeFormat: '####'
  },
  {
    value: 'pl',
    currency: 'eur',
    localizationKey: 'common.countries.poland',
    region: 'common.regions.europe',
    areaCode: '48',
    postCodeFormat: '##-###',
    inputPostCodeFormat: '##-###'
  },
  {
    value: 'pt',
    currency: 'eur',
    localizationKey: 'common.countries.portugal',
    region: 'common.regions.europe',
    areaCode: '351',
    postCodeFormat: '####-###',
    inputPostCodeFormat: '####-###'
  },
  {
    value: 'ro',
    currency: 'eur',
    localizationKey: 'common.countries.romania',
    region: 'common.regions.europe',
    areaCode: '40',
    postCodeFormat: '######',
    inputPostCodeFormat: '######'
  },
  {
    value: 'ru',
    currency: 'usd',
    localizationKey: 'common.countries.russia',
    region: 'common.regions.europe',
    areaCode: '7',
    postCodeFormat: '######',
    inputPostCodeFormat: '######'
  },
  {
    value: 'rs',
    currency: 'eur',
    localizationKey: 'common.countries.serbia',
    region: 'common.regions.europe',
    areaCode: '381',
    postCodeFormat: '#####',
    inputPostCodeFormat: '#####'
  },
  {
    value: 'sk',
    currency: 'eur',
    localizationKey: 'common.countries.slovakia',
    region: 'common.regions.europe',
    areaCode: '421',
    postCodeFormat: '### ##',
    inputPostCodeFormat: '### ##'
  },
  {
    value: 'si',
    currency: 'eur',
    localizationKey: 'common.countries.slovenia',
    region: 'common.regions.europe',
    areaCode: '386',
    postCodeFormat: '####',
    inputPostCodeFormat: '####'
  },
  {
    value: 'es',
    currency: 'eur',
    localizationKey: 'common.countries.spain',
    region: 'common.regions.europe',
    areaCode: '34',
    postCodeFormat: '#####',
    inputPostCodeFormat: '#####'
  },
  {
    value: 'se',
    currency: 'eur',
    localizationKey: 'common.countries.sweden',
    region: 'common.regions.europe',
    areaCode: '46',
    postCodeFormat: '### ##',
    inputPostCodeFormat: '### ##'
  },
  {
    value: 'ch',
    currency: 'eur',
    localizationKey: 'common.countries.switzerland',
    region: 'common.regions.europe',
    areaCode: '41',
    postCodeFormat: '####',
    inputPostCodeFormat: '####'
  },
  {
    value: 'ae',
    currency: 'usd',
    localizationKey: 'common.countries.uae',
    region: 'common.regions.middleEast',
    areaCode: '971',
    postCodeFormat: 'none',
    inputPostCodeFormat: 'none'
  },
  {
    value: 'gb',
    currency: 'eur',
    localizationKey: 'common.countries.united_kingdom',
    region: 'common.regions.europe',
    areaCode: '44',
    postCodeFormat: 'A# #AA|AA# #AA',
    inputPostCodeFormat: 'A# #AA|AA# #AA'
  },
  {
    value: 'us',
    currency: 'usd',
    localizationKey: 'common.countries.united_states',
    region: 'common.regions.america',
    areaCode: '1',
    postCodeFormat: '#####|#####-####',
    inputPostCodeFormat: '#####|#####-####'
  },
  {
    value: 'uz',
    currency: 'usd',
    localizationKey: 'common.countries.uzbekistan',
    region: 'common.regions.asia',
    areaCode: '998',
    postCodeFormat: '#####',
    inputPostCodeFormat: '#####'
  },
  {
    value: 'tm',
    currency: 'eur',
    localizationKey: 'common.countries.turkmenistan',
    region: 'common.regions.asia',
    areaCode: '993',
    postCodeFormat: '#####',
    inputPostCodeFormat: '#####'
  },
  {
    value: 'tn',
    currency: 'eur',
    localizationKey: 'common.countries.tunisia',
    region: 'common.regions.africa',
    areaCode: '216',
    postCodeFormat: '####',
    inputPostCodeFormat: '####'
  },
  {
    value: 'tj',
    currency: 'eur',
    localizationKey: 'common.countries.tajikistan',
    region: 'common.regions.asia',
    areaCode: '992',
    postCodeFormat: '######',
    inputPostCodeFormat: '######'
  },
  {
    value: 'mk',
    currency: 'eur',
    localizationKey: 'common.countries.north_macedonia',
    region: 'common.regions.europe',
    areaCode: '389',
    postCodeFormat: '####',
    inputPostCodeFormat: '####'
  },
  {
    value: 'ma',
    currency: 'eur',
    localizationKey: 'common.countries.morocco',
    region: 'common.regions.africa',
    areaCode: '212',
    postCodeFormat: '#####',
    inputPostCodeFormat: '#####'
  },
  {
    value: 'me',
    currency: 'eur',
    localizationKey: 'common.countries.montenegro',
    region: 'common.regions.europe',
    areaCode: '382',
    postCodeFormat: '#####',
    inputPostCodeFormat: '#####'
  },
  {
    value: 'mc',
    currency: 'eur',
    localizationKey: 'common.countries.monaco',
    region: 'common.regions.europe',
    areaCode: '377',
    postCodeFormat: '#####',
    inputPostCodeFormat: '#####'
  },
  {
    value: 'kw',
    currency: 'eur',
    localizationKey: 'common.countries.kuwait',
    region: 'common.regions.middleEast',
    areaCode: '965',
    postCodeFormat: '#####',
    inputPostCodeFormat: '#####'
  },
  {
    value: 'xk',
    currency: 'eur',
    localizationKey: 'common.countries.kosovo',
    region: 'common.regions.europe',
    areaCode: '383',
    postCodeFormat: '#####',
    inputPostCodeFormat: '#####'
  }
];
