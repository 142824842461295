enum GENERAL_ROUTES {
  HOME = '/',
  BASKET = '/baskets/basket',
  LIST = '/ara',
  ANONYMOUS_ORDERS = '/anonymous-tracking'
}

enum AUTH_ROUTES {
  AUTH = '/users/auth',
  REGISTER = '/users/auth?tab=register',
  FORGOT_PASSWORD = '/users/password/reset',
  EMAIL_SET_PRIMARY = '/users/email-set-primary/.+',
  CONFIRM_EMAIL = '/users/registration/account-confirm-email/.+'
}

enum ACCOUNT_ROUTES {
  ACCOUNT = '/account',
  ACCOUNT_ADDRESS = '/account/address',
  ACCOUNT_CHANGE_EMAIL = '/account/change-email',
  ACCOUNT_CHANGE_PASSWORD = '/account/change-password',
  ACCOUNT_CONTACT = '/account/contact',
  ACCOUNT_COUPONS = '/account/coupons',
  ACCOUNT_FAQ = '/account/faq',
  ACCOUNT_ORDERS = '/users/orders',
  ACCOUNT_PROFILE = '/account/profile',
  ACCOUNT_WISHLIST = '/account/favourite-products/',
  ANONYMOUS_TRACKING = '/anonymous-tracking',
  MESSAGES = '/account/messages',
  NOTIFICATIONS = '/account/notifications',
  STORE_INVOICES = '/account/store-invoices',
  CLUB_CARDS = '/account/club-cards',
  GIFT_LISTS = '/account/gift-lists',
  REVIEWS = '/account/reviews',
  FILTERS = '/account/filters',
  DIGITAL_ASSISTAN = '/account/digital-assistant'
}

enum ORDER_ROUTES {
  CHECKOUT = '/orders/checkout',
  CHECKOUT_COMPLETED = '/orders/completed'
}

enum PRODUCT_ROUTES {}

export const ROUTES = {
  ...GENERAL_ROUTES,
  ...AUTH_ROUTES,
  ...ACCOUNT_ROUTES,
  ...ORDER_ROUTES,
  ...PRODUCT_ROUTES
};
