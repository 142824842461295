const { LocaleUrlStrategy } = require('@akinon/next/localization');
const { ROUTES } = require('@theme/routes');
const { countries } = require('./countries');

const commerceUrl = encodeURI(process.env.SERVICE_BACKEND_URL ?? 'default');

const countryCurrencyMapping = countries.reduce((acc, country) => {
  acc[country.value] = country.currency;

  return acc;
}, {});

module.exports = {
  useOptimizedTranslations: true,
  commerceUrl,
  countries,
  checkout: {
    iframeExcludedPaymentOptions: ['credit-card']
  },
  commonProductAttributes: [
    { translationKey: 'color', key: 'filterable_renk' }
  ],
  localization: {
    locales: [
      ...countries.map((country) => {
        return {
          label: 'EN',
          longLabel: 'English',
          value: 'en-' + country.value,
          localePath: 'en',
          apiValue: 'en-us',
          rtl: false
        };
      }),
      ...countries.map((country) => {
        return {
          label: 'TR',
          longLabel: 'Türkçe',
          value: 'tr-' + country.value,
          localePath: 'tr',
          apiValue: 'tr-tr',
          rtl: false
        };
      })
    ],
    currencies: [
      {
        label: 'USD',
        code: 'usd'
      },
      {
        label: 'EUR',
        code: 'eur'
      },
      {
        label: 'TL',
        code: 'try'
      }
    ],
    defaultLocaleValue: 'tr-tr',
    localeUrlStrategy: LocaleUrlStrategy.HideDefaultLocale,
    redirectToDefaultLocale: false,
    defaultCurrencyCode: 'try',
    getActiveCurrencyCode: ({ req, locale, defaultCurrencyCode }) => {
      const [, countryCode] = locale && locale.split('-');
      const currencyFromCookie = req?.cookies?.['pz-set-currency'];

      if (currencyFromCookie) {
        return currencyFromCookie.toLowerCase();
      }

      return countryCurrencyMapping[countryCode] ?? defaultCurrencyCode;
    }
  },
  rewrites: [
    {
      source: ROUTES.AUTH,
      destination: '/auth'
    },
    {
      source: ROUTES.BASKET,
      destination: '/basket'
    },
    {
      source: '/ara', // The new URL you want to show
      destination: '/list' // The actual page being rendered
    },
    {
      source: ROUTES.ACCOUNT_ORDERS,
      destination: '/account/orders'
    }
  ],
  redis: {
    defaultExpirationTime: 900 // 15 min
  }
};
